import { render, staticRenderFns } from "./LoggerPageFileTable.vue?vue&type=template&id=0aba7f2c&scoped=true&"
import script from "./LoggerPageFileTable.vue?vue&type=script&lang=js&"
export * from "./LoggerPageFileTable.vue?vue&type=script&lang=js&"
import style0 from "./LoggerPageFileTable.vue?vue&type=style&index=0&id=0aba7f2c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aba7f2c",
  null
  
)

export default component.exports